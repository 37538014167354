<template>
  <section v-if="!loading && data">
    <b-button class="is-primary" @click="addOrUpdate = true">Add User</b-button>
    <br><br>
    <div class="users_categories" v-for="(role, index) in data" :key="index">
      <h1>{{ role.roleName }}</h1>
      <div class="users_categories_listed">
        <UserProfile v-for="(user, index) in role.roleUsers " :key="index" :user="user" @edit="edit" @delete="deleteUser" />
      </div>
    </div>

    <AddUpdateData v-if="addOrUpdate" @close="addOrUpdate = false; dataToEdit = null" @completed="getAllUsers" :dataToEdit="dataToEdit" />
  </section>
  <div v-else>
    <widgets v-if="loading"  />
    <Widgets type="noData" v-else :showButton="true" @add="addOrUpdate = true" />
  </div>
</template>

<script>
import { DELETE_USER, GET_ALL_USERS } from "@/store/actions/users";
import UserProfile from "../components/UserProfile";
import Widgets from '@/components/Widgets.vue';
import AddUpdateData from "../modals/AddUpdateData.vue";
export default {
  name: "Active",
  data() {
    return {
      loading: false,
      data: null,
      addOrUpdate: false,
      dataToEdit: null
    };
  },
  components: {
    UserProfile,
    Widgets,
    AddUpdateData
  },
  methods: {
    getAllUsers() {
      let vm = this;
      vm.addOrUpdate = false;
      vm.loading = true;

      vm.$store.dispatch(GET_ALL_USERS).then((res) => {
        vm.loading = false;
        if (res.data.status == 'ok') {
          this.data = res.data.data;
        } else {
          this.$notify({
            title: 'Failed',
            text: res.data.message,
            type: 'error'
          });
        }
      }).catch((error) => {
        vm.loading = false;
        if (error.response && error.response.status === 401) {
          this.$notify({
            title: "Error",
            text: "You are not authorized to perform this action.",
            type: "error",
          });
          this.$router.push('/dashboard');
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred. Please try again later.",
            type: "error",
          });
          console.log(error);
        }
      });
    },
    edit(userToEdit) {
      this.dataToEdit = userToEdit;
      this.addOrUpdate = true;
    },
    deleteUser(userToDelete) {
      this.$buefy.dialog.confirm({
        title: 'Deleting account',
        message: 'Are you sure you want to <b>delete</b> this account? This action cannot be undone.',
        confirmText: 'Delete Account',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(DELETE_USER, userToDelete).then((res) => {
            if (res.data.status == 'ok') {
              this.$notify({
                title: 'Success',
                text: res.data.message,
                type: 'success'
              });
              this.getAllUsers();
            } else {
              this.$notify({
                title: 'Failed',
                text: res.data.message,
                type: 'error'
              });
            }
          }).catch((error) => {
            this.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error);
          });
        }
      })
    }
  },
  mounted() {
    let vm = this;
    vm.getAllUsers();
  }
};
</script>

<style lang="scss">
  .users_categories {
  margin-bottom: 2rem;
    h1 {
      font-size: .9rem;
      font-weight: 500;
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 15px;
    }
    .users_categories_listed {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;
      row-gap: 60px;
    }
  }
</style>