import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthView from '@/views/Auth.vue'
import Overview from '@/views/dashboard/Overview.vue'
import DashboardLayout from '@/views/DashboardLayout.vue'
import Doctors from '@/views/dashboard/doctors/index.vue'
import Patient from '@/views/dashboard/patients/index.vue'
import Hospitals from '@/views/dashboard/hospitals/index.vue'
import Users from '@/views/dashboard/users/index.vue'
import MyPatients from '../views/dashboard/patients/pages/MyPatients.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'authPage',
    component: AuthView
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'report',
        component: Overview
      },
      {
        path: 'doctors',
        name: 'doctors',
        component: Doctors
      },
      {
        path: 'patients',
        name: 'patients',
        component: Patient
      },
      {
        path: 'my-patients',
        name: 'myPatients',
        component: MyPatients
      },
      {
        path: 'hospitals',
        name: 'hospitals',
        component: Hospitals
      },
      {
        path: 'users',
        name: 'users',
        component: Users
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({ 
  mode:'history',
  routes
})

export default router
