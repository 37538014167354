import axios from 'axios'
import { UPLOAD } from "../actions/files";
import { API_BASE_URL, UPLOAD_URL } from "../variables";

const upload = {
  state: {},
  setters: {},
  getters: {},
  mutations: {},
  actions: {
    [UPLOAD]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + UPLOAD_URL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`,
          "Content-Type": "multipart/form-data"
        },
        data: payload
      })
    }
  }
}

export default upload;