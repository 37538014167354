import axios from 'axios'
import { CREATE_HOSPITAL, DELETE_HOSPITAL, GET_ALL_HOSPITALS, UPDATE_HOSPITAL } from '../actions/hospitals';
import { API_BASE_URL, CREATE_HOSPITAL_URL, DELETE_HOSPITAL_URL, GET_ALL_HOSPITALS_URL, UPDATE_HOSPITAL_URL } from '../variables';

const hospitals = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [GET_ALL_HOSPITALS]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + GET_ALL_HOSPITALS_URL,
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          }
      })
    },
    [CREATE_HOSPITAL]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + CREATE_HOSPITAL_URL,
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          },
          data: payload
      })
    },
    [UPDATE_HOSPITAL]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + UPDATE_HOSPITAL_URL + payload.id,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          },
          data: payload
      })
    },
    [DELETE_HOSPITAL]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + DELETE_HOSPITAL_URL + payload.id,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          }
      })
    }

  }
}

export default hospitals;