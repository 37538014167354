<template>
  <div class="top-nav">
    <div class="user-data">
      <h1>{{ pageName || 'Dashboard' }}</h1>
      <div class="user-details">
        <div class="user-profile">
          <div class="profile">
            <span>{{ makeInitials(loggedInUser.fullname || '/ \\') }}</span>
          </div>
          <div class="user-profile-names">
            <h3>{{ loggedInUser.fullname }}</h3>
            <p>{{ loggedInUser.position }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Topnav',
  data() {
    return {
      loggedInUser: {}
    }
  },
  props: {
    username: {
      type: String,
      default: 'User'
    },
    position: {
      type: String,
      default: 'Default Position'
    },
    pageName: {
      type: String,
      default: 'Dashboard'
    }
  },
  methods: {
    makeInitials(name = 'User') {
      return name
        .split(' ')
        .map(word => word[0])
        .join('')
        .replace(/^(.)?(.*)/, (_, first, last) => `${first || ''}${last ? last[0].toUpperCase() : ''}`)
        .toUpperCase();
    }
  },
  mounted() {
    let vm = this;

    if(this.$parseJwt() === null) {
      this.$router.push('/login');
      return;
    }
    const loggedUser = this.$parseJwt();

    loggedUser.fullname = loggedUser.firstName + ' ' + loggedUser.lastName;
    loggedUser.position = loggedUser.role.split('_').join(' ');
    vm.loggedInUser = loggedUser;
  }
}
</script>

<style>

</style>