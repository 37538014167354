<template>
  <div class="dashboard_page main_wrapper" v-if="!loading && data.length > 0">
    <div class="filter_form">
      <form>
        <b-field label="Select a date">
          <b-datepicker
            placeholder="Choose Filter Dates..."
            v-model="dates"
            range>
          </b-datepicker>
          &nbsp;&nbsp;&nbsp;
          <b-button type="is-primary" @click="filterPatients">Filter</b-button>
        </b-field>
      </form>
      <br>
    </div>
    <b-button type="is-primary" @click="getPatients" v-if="hasFiltered">Clear Filters</b-button>
    <b-button type="is-primary" @click="downloadReport">Download</b-button>
    <br><br>
    <b-table :paginated="true" :per-page="10" pagination-size="is-small" :pagination-rounded="true" :striped="true" :hoverable="true" :data="data">
      <b-table-column field="id" label="ID" width="40" numeric>
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ ++props.index }}
        </template>
      </b-table-column>
      <b-table-column field="fullNames" label="Names" searchable>
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.fullNames }}
        </template>
      </b-table-column>
      <b-table-column field="gender" label="Gender" width="50" searchable>
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.gender }}
        </template>
      </b-table-column>
      <b-table-column field="phoneNumber" label="Phone Number" searchable>
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.phoneNumber }}
        </template>
      </b-table-column>
      
      <b-table-column field="dob" label="DOB" >
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.dob }}
        </template>
      </b-table-column>
      
      <b-table-column field="patientDoctorNames" label="Doctor" searchable >
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.patientDoctorNames }}
        </template>
      </b-table-column>

      <b-table-column field="hospitalNames" label="Hospital" searchable >
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.hospitalNames }}
        </template>
      </b-table-column>

      <b-table-column field="" label="Next Appointment" >
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.records[0] ? dayjs(props.row.records[0].nextAppointment).format('MMM D, YYYY') : 'N/A' }}
        </template>
      </b-table-column>

    </b-table>
    
  </div>
  <div class="main_wrapper" v-else>
    <widgets v-if="loading"  />
    <Widgets type="noData" v-else @add="getPatients" :showButton="hasFiltered" subTitle="No Results Found, Click Below To Get All Data" buttonText="Get All Records" />
  </div>
</template>

<script>
import Widgets from '@/components/Widgets.vue'
import { DOWNLOAD_PATIENT_RECORDS, GET_ALL_PATIENTS } from '@/store/actions/patients';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'Overview',
  components: {
    Widgets
  },
  data() {
    return {
      loading: true,
      data: [],
      dayjs: dayjs,
      dates: [],
      hasFiltered: false
    }
  },
  methods: {
    addingData() {
      console.log('Adding Data')
    },
    getPatients() {
      let vm = this;
      vm.addOrUpdate = false;
      vm.loading = true;
      this.dates = [];

      vm.$store.dispatch(GET_ALL_PATIENTS).then((res) => {
        vm.loading = false;
        this.hasFiltered = false;
        if (res.data.status == 'ok') {
          res.data.data.forEach((patient) => {
            patient.fullNames = `${patient.firstName} ${patient.lastName}`;
            // patient.hospitalName = patient.Hospitals[0] ? patient.Hospitals[0].name : 'N/A';
            patient.patientDoctorNames = patient.patientDoctor ? `${patient.patientDoctor.user.firstName} ${patient.patientDoctor.user.lastName}` : 'N/A';
            patient.hospitalNames = patient.patientDoctor.user.Hospitals[0] ? patient.patientDoctor.user.Hospitals[0].name : 'N/A';
          })
          this.data = res.data.data;
          
        } else {
          this.$notify({
            title: 'Failed',
            text: res.data.message,
            type: 'error'
          });
        }
      }).catch((error) => {
        vm.loading = false;
        if (error.response && error.response.status === 401) {
          this.$notify({
            title: "Error",
            text: "You are not authorized to perform this action.",
            type: "error",
          });
          this.$router.push('/dashboard');
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred. Please try again later.",
            type: "error",
          });
          console.log(error);
        }
      });
    },
    filterPatients() {
      if (this.dates.length == 0) {
        this.$notify({
          title: 'Error Filter',
          text: 'Please select a date range to filter the patients.',
          type: 'error'
        });
        return;
      } else {
        let vm = this;
        vm.loading = true;
        let startDate = dayjs(this.dates[0]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        let endDate = dayjs(this.dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        
        vm.$store.dispatch(GET_ALL_PATIENTS, { startDate, endDate, download: false }).then((res) => {
          vm.loading = false;
          this.hasFiltered = true;
          if (res.data.status == 'ok') {
            res.data.data.forEach((patient) => {
              patient.fullNames = `${patient.firstName} ${patient.lastName}`;
              patient.patientDoctorNames = patient.patientDoctor ? `${patient.patientDoctor.user.firstName} ${patient.patientDoctor.user.lastName}` : 'N/A';
              patient.hospitalNames = patient.patientDoctor.user.Hospitals[0] ? patient.patientDoctor.user.Hospitals[0].name : 'N/A';
            })
            this.data = res.data.data;
          } else {
            this.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          if (error.response && error.response.status === 401) {
            this.$notify({
              title: "Error",
              text: "You are not authorized to perform this action.",
              type: "error",
            });
            this.$router.push('/dashboard');
          } else {
            this.$notify({
              title: "Error",
              text: "An error occurred. Please try again later.",
              type: "error",
            });
            console.log(error);
          }
        });
      }

    },
    downloadReport() {
      let vm = this;
      // vm.loading = true;
      let startDate = null;
      let endDate = null;
      if (this.dates.length > 0) {
        startDate = dayjs(this.dates[0]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        endDate = dayjs(this.dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      }
      
      vm.$store.dispatch(DOWNLOAD_PATIENT_RECORDS, { startDate: startDate, endDate: endDate, download: true }).then((res) => {
        const contentDisposition = res.headers['content-disposition'];

        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'example.xlsx';
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }).catch((error) => {
      });
    }
  },
  mounted() {
    this.getPatients();
  }
}
</script>

<style>

</style>