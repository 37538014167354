<template>
  <div class="dashboard_page">
    <div>
      <b-tabs :destroy-on-hide="false">
        <b-tab-item label="Active">
          <Active @add="showModal = true" />
        </b-tab-item>
      </b-tabs>
    </div>
    <AddUpdateDataVue @close="showModal = false" v-if="showModal" />
  </div>
</template>

<script>
import Active from "./pages/Active";
import Inactive from "./pages/Inactive";
import AddUpdateDataVue from "./modals/AddUpdateData";

export default {
  name: "Users",
  data() {
    return {
      currentPage: "Active",
      showModal: false,
    };
  },
  components: {
    Active,
    Inactive,
    AddUpdateDataVue
  },
  methods: {
  },
};
</script>

<style>
</style>