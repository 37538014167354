<template>
  <Modal
    :title="modalTitle"
    @close="$emit('close')"
    :loading="loading"
    @submit="addPatient"
  >
    <form action="">
      <b-field grouped>
        <b-field label="First Name" expanded>
          <b-input
            placeholder="First Name"
            v-model="formData.firstName"
            expanded
            required
          ></b-input>
        </b-field>
        <b-field label="Last Name" expanded>
          <b-input
            placeholder="Last Name"
            v-model="formData.lastName"
            expanded
            required
          ></b-input>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Phone Number" expanded>
          <b-input
            placeholder="Phone Number"
            v-model="formData.phoneNumber"
            expanded
            required
          ></b-input>
        </b-field>
        <b-field label="Gender" expanded>
          <b-radio name="name" v-model="formData.gender" native-value="Male">
            Male
          </b-radio>
          <b-radio name="name" v-model="formData.gender" native-value="Female">
            Female
          </b-radio>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Province" expanded>
          <b-input
            placeholder="Province"
            v-model="formData.province"
            expanded
            required
          ></b-input>
        </b-field>
        <b-field label="District" expanded>
          <b-input
            placeholder="District"
            v-model="formData.district"
            expanded
            required
          ></b-input>
        </b-field>
        <b-field label="Site Of Tumor" expanded>
          <b-select
            placeholder="Select Site Of Tumor"
            required
            expanded
            v-model="formData.siteOfTumor"
          >
            <option value="CERVIX">Cervix</option>
          </b-select>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Date of Birth" expanded>
          <b-datepicker
            v-model="formData.dob"
            class="date-dropdown"
            placeholder="Date of Birth"
            expanded
            required
          ></b-datepicker>
        </b-field>
        <b-field label="Doctor" expanded>
          <b-select
            placeholder="Select Doctor"
            required
            expanded
            v-model="formData.doctorId"
          >
            <option
              v-for="(doctor, index) in allDoctors"
              :value="doctor.id"
              :key="index"
            >
              {{ doctor.firstName }} {{ doctor.lastName }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Cancer Stage" expanded>
          <b-select
            placeholder="Choose Stage Of Your Cancer"
            required
            expanded
            v-model="formData.stage"
          >
            <option
              v-for="stage in 4"
              :value="stage"
              :key="stage"
            >
              Stage {{ stage }}
            </option>
          </b-select>
        </b-field>
      </b-field>
      <b-field label="Histology Type">
        <b-radio v-model="formData.histologyType" required native-value="SCC">
          SCC
        </b-radio>
        <b-radio v-model="formData.histologyType" required native-value="ADENOCARCINOMA">
          Adenocarcinoma
        </b-radio>
      </b-field>
      <b-field>
        <b-input v-model="formData.otherHistologyType" placeholder="Is There Any Other Histology Type?"></b-input>
      </b-field>
      
      <b-field label="Treatment Received">
        <b-radio v-model="formData.treatmentReceived" required native-value="SURGERY">
          Surgery
        </b-radio>
        <b-radio v-model="formData.treatmentReceived" required native-value="CHEMOTHERAPY">
          Chemotherapy
        </b-radio>
        <b-radio v-model="formData.treatmentReceived" required native-value="RADIOTHERAPY">
          Radiotherapy
        </b-radio>
        <b-radio v-model="formData.treatmentReceived" required native-value="CHEMORADIOTHERAPY">
          Chemoradiotherapy
        </b-radio>
      </b-field>
      <b-field>
        <b-input v-model="formData.otherTreatmentReceived" placeholder="Is There Any Other Treatment Received?"></b-input>
      </b-field>

      <b-field label="Patient Info">
        <VueEditor
          v-model="formData.patientInfo"
          :editorToolbar="customToolbar"
        />
      </b-field>
      <b-field label="Patient File" expanded>
        <b-upload
          v-model="dropFiles"
          native
          multiple
          drag-drop
          @input="fileUpload"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"> </b-icon>
              </p>
              <p>Drop your files here or click to upload</p>
              <span v-if="dataToEdit"
                >Leave empty, if you don't want to change it</span
              >
            </div>
          </section>
        </b-upload>
      </b-field>
      <div class="tags">
        <span
          v-for="(file, index) in dropFiles"
          :key="index"
          class="tag is-primary"
        >
          {{ file.name }}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
          ></button>
        </span>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { CREATE_PATIENT, UPDATE_PATIENT } from "@/store/actions/patients";
import { UPLOAD } from "@/store/actions/files";
import { VueEditor } from "vue2-editor";

export default {
  name: "AddUpdateData",
  components: {
    Modal,
    VueEditor,
  },
  props: {
    dataToEdit: {
      type: Object,
      default: () => ({}),
    },
    allDoctors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        gender: "",
        dob: new Date(),
        patientFile: "",
        patientInfo: "",
        doctorId: 1,
        siteOfTumor: 'CERVIX',
        province: '',
        district: '',
        stage: '',
        histologyType: null,
        otherHistologyType: '',
        treatmentReceived: null,
        otherTreatmentReceived: ''
      },
      modalTitle: "Add Patient",
      dropFiles: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          { list: "check" },
        ],
        [{ align: [] }],
        ["clean"],
      ],
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    addPatient() {
      let vm = this;
      vm.loading = true;

      if (
        this.formData.firstName === "" ||
        this.formData.lastName === "" ||
        this.formData.phoneNumber === "" ||
        this.formData.gender === "" ||
        this.formData.dob === "" ||
        this.formData.province === "" ||
        this.formData.district === "" ||
        this.formData.stage === "" ||
        this.formData.histologyType === "" ||
        this.formData.treatmentReceived === "" ||
        this.formData.siteOfTumor === ""
      ) {
        vm.loading = false;
        vm.$notify({
          title: "Failed",
          text: "Please fill all required fields",
          type: "error",
        });
        return;
      }

      if (this.dataToEdit) {
        vm.$store
          .dispatch(UPDATE_PATIENT, this.formData)
          .then((res) => {
            vm.loading = false;
            if (res.data.status == "ok") {
              vm.$notify({
                title: "Success",
                text: res.data.message,
                type: "success",
              });
              vm.$emit("completed");
              vm.$emit("close");
            } else {
              vm.$notify({
                title: "Failed",
                text: res.data.message,
                type: "error",
              });
            }
          })
          .catch((error) => {
            vm.loading = false;
            if (error.response && error.response.status === 400) {
              error.response.data.message.forEach((message) => {
                this.$notify({
                  title: "Error Warning",
                  text: message,
                  type: "error",
                });
              });
            } else {
              vm.$notify({
                title: "Error Warning",
                text: "An error occurred. Please try again later.",
                type: "error",
              });
              console.log(error);
            }
          });
      } else {
        vm.$store
          .dispatch(CREATE_PATIENT, this.formData)
          .then((res) => {
            vm.loading = false;
            if (res.data.status == "ok") {
              vm.$notify({
                title: "Success",
                text: res.data.message,
                type: "success",
              });
              vm.$emit("completed");
              vm.$emit("close");
            } else {
              vm.$notify({
                title: "Failed",
                text: res.data.message,
                type: "error",
              });
            }
          })
          .catch((error) => {
            vm.loading = false;
            if (error.response && error.response.status === 400) {
              error.response.data.message.forEach((message) => {
                this.$notify({
                  title: "Error Warning",
                  text: message,
                  type: "error",
                });
              });
            } else {
              vm.$notify({
                title: "Error Warning",
                text: "An error occurred. Please try again later.",
                type: "error",
              });
              console.log(error);
            }
          });
      }
    },
    fileUpload(file) {
      const attachmentData = new FormData();
      attachmentData.append("file", file[0]);

      if (file) {
        this.modalTitle = "Uploading...";
        this.loading = true;
        this.$store
          .dispatch(UPLOAD, attachmentData)
          .then((res) => {
            this.loading = false;
            this.modalTitle = "Add Patient";
            if (res.data.status === "success") {
              this.formData.patientFile = res.data.file;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$notify({
              title: "Error",
              text: "An error occurred while uploading. Please try again later.",
              type: "error",
            });
          });
      }
    },
  },
  mounted() {
    if (this.dataToEdit) {
      this.modalTitle = "Update Patient";
      this.formData = {
        firstName: this.dataToEdit.firstName,
        lastName: this.dataToEdit.lastName,
        phoneNumber: this.dataToEdit.phoneNumber,
        gender: this.dataToEdit.gender,
        dob: new Date(this.dataToEdit.dob),
        patientInfo: this.dataToEdit.patientInfo,
        doctorId: this.dataToEdit.patientDoctor.user.id,
        patientFile: this.dataToEdit.patientFile,
        id: this.dataToEdit.id,
        siteOfTumor: this.dataToEdit.siteOfTumor,
        province: this.dataToEdit.province,
        district: this.dataToEdit.district,
        stage: this.dataToEdit.stage,
        histologyType: this.dataToEdit.histologyType,
        otherHistologyType: this.dataToEdit.otherHistologyType,
        treatmentReceived: this.dataToEdit.treatmentReceived,
        otherTreatmentReceived: this.dataToEdit.otherTreatmentReceived
      };
    }
  },
};
</script>

<style>
</style>