<template>
  <div class="user_profiles">
    <div class="avatar">
      {{ makeInitials(user.firstName + ' ' + user.lastName) }}
      <svg v-if="!user.isActivated" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 7.58172 7.58172 4 12 4C13.6284 4 15.1432 4.48652 16.4068 5.32214L6.07523 17.3757C4.78577 15.9554 4 14.0694 4 12ZM7.59321 18.6779C8.85689 19.5135 10.3716 20 12 20C16.4183 20 20 16.4183 20 12C20 9.93057 19.2142 8.04467 17.9248 6.62436L7.59321 18.6779ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" ></path> </g></svg>
    </div>
    <div class="user_info">
      <h3>{{ user.firstName + ' ' + user.lastName }}</h3>
      <p>{{ user.role }}</p>
    </div>

    <div class="action_buttons">
      <b-button type="is-danger" icon-left="delete" @click="$emit('delete', user)"></b-button>
      &nbsp;
      <b-button type="is-primary" icon-left="pencil" @click="$emit('edit', user)"></b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({
        id: 1,
        firstName: "Jesse",
        lastName: "Simmons",
        role: "Super Admin"
      })
    }
  },
  data() {
    return {
      userData: this.user
    }
  },
  methods: {
    makeInitials(name = 'User') {
      return name
        .split(' ')
        .map(word => word[0])
        .join('')
        .replace(/^(.)?(.*)/, (_, first, last) => `${first || ''}${last ? last[0].toUpperCase() : ''}`)
        .toUpperCase();
    }
  }
}
</script>

<style lang="scss">
  .user_profiles {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      background-color: #f0f0f0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      position: relative;
      svg {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        fill: #00193D;
        opacity: .5;
      }
    }
    .user_info {
      h3 {
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
      }
      p {
        font-size: 0.8rem;
        margin: 0;
      }
    }
    // .action_buttons {
    //   button {
    //     background-color: #f0f0f0;
    //     border: none;
    //     padding: 5px;
    //     border-radius: 5px;
    //     svg {
    //       width: 20px;
    //       height: 20px;
    //       fill: #00193D;
    //     }
    //   }
    // }
  }

</style>