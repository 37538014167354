<template>
  <div class="visit_page">
    <div class="title_head">
      <h1 class="main">
        <b-button
          type="is-primary"
          @click="$emit('goback')"
          icon-left="arrow-left"
        ></b-button>
        Visit Page
      </h1>

      <div>
        <b-button type="is-primary" @click="goToVisit = true"
          >Add Visit</b-button
        >
        &nbsp;
        <b-button type="is-warning" @click="goToAssess = true" v-if="isAdmin"
          >Make Assessment</b-button
        >
      </div>
    </div>

    <div v-if="patientData">
      <div class="patient_info">
        <div class="names">
          <h1>
            Names: <b>{{ patientData.firstName }} {{ patientData.lastName }}</b>
          </h1>
          <h2>
            Phone: <b>{{ patientData.phoneNumber }}</b>
          </h2>
          <h2>
            Gender: <b>{{ patientData.gender }}</b>
          </h2>
          <h2>
            DOB: <b>{{ patientData.dob }}</b>
          </h2>
          <h2>
            Site Of Tumor: <b>{{ patientData.siteOfTumor }}</b>
          </h2>
          <h2>
            Address:
            <b>{{ patientData.province }}, {{ patientData.district }}</b>
          </h2>
          <h2>
            Cancer Stage: <b>{{ patientData.stage }}</b>
          </h2>
          <h2>
            Histology Type: <b>{{ patientData.histologyType }}</b>
          </h2>
          <h2>
            Treatment Received: <b>{{ patientData.treatmentReceived }}</b>
          </h2>
        </div>
        <div class="details">
          <h2 class="title">Patient Info:</h2>
          <div class="details_info">
            <div
              v-html="patientData.patientInfo"
              v-if="
                patientData.patientInfo && patientData.patientInfo.length > 0
              "
            ></div>
            <div v-else>
              <p>No patient info available</p>
            </div>

            <b-tag type="is-success" v-if="patientData.records.length > 0"
              >Next Appointment:
              {{
                dayjs(patientData.records[0].nextAppointment).format(
                  "ddd, MMM D, YYYY"
                )
              }}</b-tag
            >
            <br />
            <br />
            <b-button
              tag="a"
              :href="assetsBaseUrl + patientData.patientFile"
              type="is-primary"
              size="is-small"
              v-if="patientData.patientFile"
              download="Hello World"
              >Download Patient File</b-button
            >
          </div>
        </div>
      </div>
      <hr />
      <b-tabs :destroy-on-hide="false" v-model="currentTab" class="down-tabs">
        <b-tab-item label="Visits">
          <div class="patients_records">
            <h1>Patient's Visits</h1>
            <div v-if="patientData.records.length > 0">
              <div
                class="patient_record"
                v-for="(record, index) in patientData.records"
                :key="index"
              >
                <p class="visit_note">
                  Visit Done On:
                  {{
                    dayjs(record.createdAt).format("ddd, MMM D, YYYY h:mm A")
                  }}
                </p>
                <b-tag type="is-warning"
                  >Next Appointment Was On:
                  {{
                    dayjs(record.nextAppointment).format("ddd, MMM D, YYYY")
                  }}</b-tag
                >
                <h2 class="title">Patient Notes</h2>
                <div
                  class="patient_record_body"
                  v-html="record.patientNotes"
                  v-if="record.patientNotes"
                ></div>
                <div v-else>
                  <p>No Patient Data</p>
                  <br />
                </div>
                <div label="">
                  <h2 class="label">Patient Complaints</h2>
                  <b-checkbox v-model="record.fatigue" disabled class="readonly-checkbox">Fatigue</b-checkbox>
                  <b-checkbox v-model="record.nausea" disabled class="readonly-checkbox">Nausea</b-checkbox>
                  <b-checkbox v-model="record.vomiting" disabled class="readonly-checkbox">Vomiting</b-checkbox>
                  <b-checkbox v-model="record.diarrhea" disabled class="readonly-checkbox">Diarrhea</b-checkbox>
                  <b-checkbox v-model="record.pain" disabled class="readonly-checkbox">Pain</b-checkbox>
                  <b-checkbox v-model="record.bleeding" disabled class="readonly-checkbox">Bleeding</b-checkbox>
                  <b-checkbox v-model="record.weightLoss" disabled class="readonly-checkbox">Weight Loss</b-checkbox>
                  <b-checkbox v-model="record.vaginalDischarge" disabled class="readonly-checkbox">Vaginal Discharge</b-checkbox>
                  <b-checkbox v-model="record.vaginalBleeding" disabled class="readonly-checkbox">Vaginal Bleeding</b-checkbox>
                  <b-checkbox v-model="record.painDuringSex" disabled class="readonly-checkbox">Pain During Sex</b-checkbox>
                </div>
                <br>
                <div>
                  <h2 class="label">Other Patient Complaints</h2>
                  <b-input readonly placeholder="Other Patient Complaints" v-model="record.otherComplaints" v-if="record.otherComplaints" />
                  <p v-else>No Other Complaints From Patient</p>
                </div>

                <hr>
                
                <div>
                  <h2 class="label">Physical Exam Findings</h2>
                  <b-checkbox v-model="record.normal" disabled class="readonly-checkbox">Normal</b-checkbox>
                  <b-checkbox v-model="record.vaginalDischargeExam" disabled class="readonly-checkbox">Vaginal Discharge</b-checkbox>
                  <b-checkbox v-model="record.massPalpable" disabled class="readonly-checkbox">Mass Palpable</b-checkbox>
                  <b-checkbox v-model="record.ulceration" disabled class="readonly-checkbox">Ulceration</b-checkbox>
                  <b-checkbox v-model="record.tenderness" disabled class="readonly-checkbox">Tenderness</b-checkbox>
                  <b-checkbox v-model="record.vaginalStenosis" disabled class="readonly-checkbox">Vaginal Stenosis</b-checkbox>
                  <b-checkbox v-model="record.cervicalMass" disabled class="readonly-checkbox">Cervical Mass</b-checkbox>
                </div>
                <br>
                <div>
                  <h2 class="label">Other Physical Exam Findings</h2>
                  <b-input readonly placeholder="Other Physical Exam Findings" v-model="record.othersPhysical" v-if="record.othersPhysical" />
                  <p v-else>No Other Physical Exam Findings</p>
                </div>

                <hr>

                <div>
                  <h2 class="label">Requested Test / Exams</h2>
                  <b-checkbox v-model="record.cbc" disabled class="readonly-checkbox">CBC</b-checkbox>
                  <b-checkbox v-model="record.chemistryPanel" disabled class="readonly-checkbox">Chemistry Panel</b-checkbox>
                  <b-checkbox v-model="record.pelvicUltrasound" disabled class="readonly-checkbox">Pelvic Ultrasound</b-checkbox>
                  <b-checkbox v-model="record.ctScan" disabled class="readonly-checkbox">CT Scan</b-checkbox>
                  <b-checkbox v-model="record.mri" disabled class="readonly-checkbox">MRI</b-checkbox>
                </div>

                <br>

                <div>
                  <h2 class="label">Other Requested Test / Exams</h2>
                  <b-input readonly placeholder="Other Requested Test / Exams" v-model="record.otherRequested" v-if="record.otherTests" />
                  <p v-else>No Other Requested Test / Exams</p>
                </div>

                <hr>
                
                <div>
                  <h2 class="label">Comment</h2>
                  <b-input readonly placeholder="Comment" v-model="record.comments" v-if="record.comments" />
                  <p v-else>No Comment</p>
                </div>

                <br>
                <b-button
                  type="is-primary"
                  size="is-small"
                  v-if="record.attachment"
                  tag="a"
                  :href="assetsBaseUrl + record.attachment"
                  >Download Attachment</b-button
                >
                &nbsp;
                <b-button
                  type="is-success"
                  size="is-small"
                  @click="
                    dataToEdit = record;
                    goToVisit = true;
                  "
                  >Edit</b-button
                >
                &nbsp;
                <b-button
                  type="is-danger"
                  size="is-small"
                  @click="deletePatientRecord(record)"
                  >Delete</b-button
                >
              </div>
            </div>
            <div v-else>
              <p>Patient Has No Visits Yet</p>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Assessments" v-if="isAdmin">
          <div class="patients_records">
            <h1>Patient's Assessments</h1>
            <div v-if="patientData.assessments.length > 0">
              <div
                class="patient_record"
                v-for="(record, index) in patientData.assessments"
                :key="index"
              >
                <p class="visit_note">
                  Assement Done On:
                  {{
                    dayjs(record.createdAt)
                      .utc()
                      .format("ddd, MMM D, YYYY h:mm A")
                  }}
                </p>
                <br />
                <div
                  class="assessment_info"
                  v-for="(question, index) in questionSections"
                  :key="index"
                >
                  <h2>
                    {{ question.id }}. {{ question.text }} &nbsp;
                    <b-tag type="is-warning">{{
                      record[`q${question.id}`].split("_").join(" ")
                    }}</b-tag>
                  </h2>
                </div>
                <b-button
                  type="is-danger"
                  size="is-small"
                  icon-left="delete"
                  @click="deleteAssessment(record)"
                  >Delete</b-button
                >
              </div>
            </div>
            <div v-else>
              <p>Patient Has No Assessments Yet</p>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <Widgets v-else :loading="loading" />
    <AddUpdateRecordsVue
      v-if="goToVisit && patientData"
      @completed="getPatientRecords"
      @close="goToVisit = false"
      :patientId="patientData.id"
      :dataToEdit="dataToEdit"
    />
    <AddUpdateAssessment
      v-if="goToAssess && patientData"
      @completed="getPatientRecords"
      @close="goToAssess = false"
      :patientId="patientData.id"
      :dataToEdit="dataToEdit"
    />
  </div>
</template>

<script>
import AddUpdateRecordsVue from "../modals/AddUpdateRecords.vue";
import AddUpdateAssessment from "../modals/AddUpdateAssessment.vue";
import {
  DELETE_PATIENT_ASSESSMENT,
  DELETE_PATIENT_RECORD,
  GET_PATIENT,
} from "@/store/actions/patients";
import Widgets from "@/components/Widgets.vue";
import dayjs from "dayjs";
import { API_BASE_URL, ASSETS_BASE_URL } from "@/store/variables";
export default {
  name: "VisitPage",
  components: {
    AddUpdateRecordsVue,
    Widgets,
    AddUpdateAssessment,
  },
  props: {
    patientToVisit: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dropFiles: [],
      loading: true,
      goToVisit: false,
      goToAssess: false,
      patientData: null,
      dayjs: dayjs,
      dataToEdit: null,
      assetsBaseUrl: ASSETS_BASE_URL,
      currentTab: 0,
      isAdmin: false,
      questionSections: [
        { id: 31, text: "Have you had cramps in your abdomen?" },
        {
          id: 32,
          text: "Have you had difficulty in controlling your bowels?",
        },
        { id: 33, text: "Have you had blood in your stools (motions)?" },
        { id: 34, text: "Did you pass water/urine frequently?" },
        {
          id: 35,
          text: "Have you had pain or a burning feeling when passing water/urinating?",
        },
        { id: 36, text: "Have you had leaking of urine?" },
        { id: 37, text: "Have you had difficulty emptying your bladder?" },
        { id: 38, text: "Have you had swelling in one or both legs?" },
        { id: 39, text: "Have you had pain in your lower back?" },
        {
          id: 40,
          text: "Have you had tingling or numbness in your hands or feet?",
        },
        {
          id: 41,
          text: "Have you had irritation or soreness in your vagina or vulva?",
        },
        { id: 42, text: "Have you had discharge from your vagina?" },
        {
          id: 43,
          text: "Have you had abnormal bleeding from your vagina?",
        },
        { id: 44, text: "Have you had hot flushes and/or sweats?" },
        {
          id: 45,
          text: "Have you felt physically less attractive as a result of your disease or treatment?",
        },
        {
          id: 46,
          text: "Have you felt less feminine as a result of your disease or treatment?",
        },
        { id: 47, text: "Have you felt dissatisfied with your body?" },
        { id: 48, text: "Have you worried that sex would be painful?" },
        { id: 49, text: "Have you been sexually active?" },
        {
          id: 50,
          text: "Has your vagina felt dry during sexual activity?",
        },
        { id: 51, text: "Has your vagina felt short?" },
        { id: 52, text: "Has your vagina felt tight?" },
        {
          id: 53,
          text: "Have you had pain during sexual intercourse or other sexual activity?",
        },
        { id: 54, text: "Was sexual activity enjoyable for you?" },
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          { list: "check" },
        ],
        [{ align: [] }],
        ["clean"],
      ],
      questionsAnswers: [],
    };
  },
  methods: {
    getPatientRecords() {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch(GET_PATIENT, { id: vm.patientToVisit })
        .then((res) => {
          vm.loading = false;
          if (res.data.status == "ok") {
            vm.patientData = res.data.data;
            // vm.questionsAnswers should equal res.data.
          } else {
            vm.$notify({
              title: "Error",
              text: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          vm.loading = false;
          if (error.response && error.response.status === 400) {
            error.response.data.message.forEach((message) => {
              vm.$notify({
                title: "Error Warning",
                text: message,
                type: "error",
              });
            });
          }
        });
    },
    deleteAssessment(data) {
      this.$buefy.dialog.confirm({
        title: "Deleting Assessment",
        message:
          "Are you sure you want to <b>delete</b> this assessment record? This action cannot be undone.",
        confirmText: "Delete Assessment Record",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$store
            .dispatch(DELETE_PATIENT_ASSESSMENT, data)
            .then((res) => {
              if (res.data.status == "ok") {
                this.$notify({
                  title: "Success",
                  text: res.data.message,
                  type: "success",
                });
                this.getPatientRecords();
              } else {
                this.$notify({
                  title: "Failed",
                  text: res.data.message,
                  type: "error",
                });
              }
            })
            .catch((error) => {
              this.$notify({
                title: "Error Warning",
                text: "An error occurred. Please try again later.",
                type: "error",
              });
              console.log(error);
            });
        },
      });
    },
    deletePatientRecord(data) {
      this.$buefy.dialog.confirm({
        title: "Deleting Patient Record",
        message:
          "Are you sure you want to <b>delete</b> this patient record? This action cannot be undone.",
        confirmText: "Delete Patient Record",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$store
            .dispatch(DELETE_PATIENT_RECORD, data)
            .then((res) => {
              if (res.data.status == "ok") {
                this.$notify({
                  title: "Success",
                  text: res.data.message,
                  type: "success",
                });
                this.getPatientRecords();
              } else {
                this.$notify({
                  title: "Failed",
                  text: res.data.message,
                  type: "error",
                });
              }
            })
            .catch((error) => {
              this.$notify({
                title: "Error Warning",
                text: "An error occurred. Please try again later.",
                type: "error",
              });
              console.log(error);
            });
        },
      });
    },
  },
  mounted() {
    this.getPatientRecords();
    if (
      this.$parseJwt().role == "SUPER_ADMIN" ||
      this.$parseJwt().role == "ASSISTANT"
    ) {
      this.isAdmin = true;
    }
  },
};
</script>

<style lang="scss">
.visit_page {
  position: fixed;
  top: 60px;
  right: 0;
  height: calc(100vh - 60px);
  z-index: 100;
  background: white;
  width: calc(100% - 270px);
  padding: 20px;
  overflow-y: auto;
  ul,
  ol {
    li {
      list-style-position: inside !important;
    }
  }
  .title_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h1.main {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    column-gap: 10px;
    button {
      color: white;
    }
  }
  .patient_info {
    .names {
      text-align: left;
      h1 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      h2.title {
        font-size: 0.9rem;
        margin-bottom: 10px;
      }
    }
    .details {
      padding: 15px;
      border-radius: 10px;
      margin-top: 20px;
      background: #f5f5f5;
      text-align: left;
      h2 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      .details_info {
        font-size: 0.9rem;
        margin-bottom: 10px;
        span.tag.is-success {
          margin: 7px 0 15px;
          font-size: 0.86rem;
        }
      }
    }
  }
  .patients_records {
    .patient_record {
      padding: 15px;
      border-radius: 10px;
      margin-top: 30px;
      // background: #f6f6f6;
      background: #f9f9f9;
      border: 1px solid #aeaeaec5;
      text-align: left;

      .readonly-checkbox.is-disabled {
        opacity: 1; 
        cursor: default;
      }

      .readonly-checkbox.is-disabled .control-label {
        color: #666;
      }

      hr {
        background: #27272719;
      }

      p.visit_note {
        font-size: 0.9rem;
        // margin-bottom: 10px;
      }
      span.tag.is-warning {
        margin: 7px 0 15px;
        font-size: 0.86rem;
      }
      .patient_record_title {
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          color: white;
        }
      }
      h2.title {
        font-size: 1rem;
        margin-bottom: 10px;
        align-items: center;
        display: flex;
        column-gap: 20px;
      }
      .patient_record_body {
        text-align: left;
        margin-bottom: 40px;
        p {
          font-size: 0.9rem;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>