export const API_BASE_URL = process.env.NODE_ENV === 'production' ? "https://api.ca-care.org" : "http://localhost:3091";
export const ASSETS_BASE_URL = API_BASE_URL + '/uploads/';

// Auth
export const AUTH_LOGIN_URL = "/users/login"

// User 
export const GET_ALL_USERS_URL = "/users/get/all"
export const CREATE_USER_URL = "/users/create-user"
export const UPDATE_USER_URL = "/users/update/"
export const DELETE_USER_URL = "/users/delete/"
export const GET_ALL_DOCTORS_URL = "/users/doctors"
export const GET_ALL_MY_PATIENTS_URL = "/users/my-patients"

// Hospitals
export const GET_ALL_HOSPITALS_URL = "/hospitals/get/all"
export const CREATE_HOSPITAL_URL = "/hospitals/create-hospital"
export const UPDATE_HOSPITAL_URL = "/hospitals/update/"
export const DELETE_HOSPITAL_URL = "/hospitals/delete/"

// Patients
export const GET_ALL_PATIENTS_URL = "/patients/get/all"
export const GET_PATIENT_URL = "/patients/get/"
export const CREATE_PATIENT_URL = "/patients/create-patient"
export const UPDATE_PATIENT_URL = "/patients/update/"
export const DELETE_PATIENT_URL = "/patients/delete/"
export const CREATE_PATIENT_RECORD_URL = "/patients/create-record"
export const UPDATE_PATIENT_RECORD_URL = "/patients/update-record/"
export const DELETE_PATIENT_RECORD_URL = "/patients/delete-record/"
export const CREATE_PATIENT_ASSESSMENT_URL = "/patients/create-assessment"
export const UPDATE_PATIENT_ASSESSMENT_URL = "/patients/update-assessment/"
export const DELETE_PATIENT_ASSESSMENT_URL = "/patients/delete-assessment/"
export const GET_PATIENT_ASSESSMENT_URL = "/patients/get-record/"

// Upload
export const UPLOAD_URL = "/files/upload"
export const DOWNLOAD_URL = "/files/download/"