<template>
  <widgets type="noData"/>
</template>

<script>
import Widgets from '@/components/Widgets.vue'
export default {
  name: 'Inactive',
  components: {
    Widgets
  },

}
</script>

<style>

</style>