<template>
  <Modal :title="modalTitle" @close="$emit('close')" :loading="loading" @submit="addDoctor">
    <form action="">
      <b-field grouped>
        <b-field label="First Name" expanded>
          <b-input placeholder="First Name" v-model="formData.firstName" expanded required></b-input>
        </b-field>
        <b-field label="Last Name" expanded>
          <b-input placeholder="Last Name" v-model="formData.lastName" expanded required></b-input>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Phone Number" expanded>
          <b-input placeholder="Phone Number" v-model="formData.phoneNumber" expanded required></b-input>
        </b-field>
        <b-field label="Email" expanded>
          <b-input placeholder="Email" expanded type="email" required v-model="formData.email"></b-input>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Hospital" required expanded>
          <b-select placeholder="Select Hospital" expanded required v-model="formData.hospital">
            <option :value="hospital.id" v-for="(hospital, index) in allHospitals" :key="index">{{ hospital.name }}</option>
          </b-select>
        </b-field>
      </b-field>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { CREATE_USER, UPDATE_USER } from '@/store/actions/users';

export default {
  name: 'AddUpdateData',
  components: {
    Modal
  }, 
  props: {
    allHospitals: {
      type: Array,
      default: []
    },
    dataToEdit: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modalTitle: 'Add Doctor',
      loading: false,
      formData: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        hospital: null
      }
    }
  },
  methods: {
    addDoctor() {
      let vm = this;
      vm.loading = true;

      if(this.formData.firstName == '' || this.formData.lastName == '' || this.formData.phoneNumber == '' || this.formData.email == '' || this.formData.hospital == null) {
        vm.loading = false;
        vm.$notify({
          title: 'Failed',
          text: 'Please fill all required fields',
          type: 'error'
        });
        return;
      }

      this.formData.role = 'DOCTOR';
      this.formData.isActivated = false;
      this.formData.password = '#Doctor1234';
      this.formData.hospitals = [this.formData.hospital];

      if(this.dataToEdit) {
        this.$store.dispatch(UPDATE_USER, this.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
            vm.$emit('close');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          vm.$notify({
            title: 'Error Warning',
            text: 'An error occurred. Please try again later.',
            type: 'error'
          });
          console.log(error);
        });
      } else {
        this.$store.dispatch(CREATE_USER, this.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
            vm.$emit('close');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false
          if(error.response && error.response.status === 400) {
            error.response.data.message.forEach((message) => {
              this.$notify({
                title: 'Error Warning',
                text: message,
                type: 'error'
              });
            });
          } else {
            this.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error);
          }
        });
      } 
    }
  },
  mounted() {
    if(this.dataToEdit) {
      this.modalTitle = 'Update Doctor';
      this.formData = {
        firstName: this.dataToEdit.firstName,
        lastName: this.dataToEdit.lastName,
        phoneNumber: this.dataToEdit.phoneNumber,
        email: this.dataToEdit.email,
        id: this.dataToEdit.id,
        hospital: this.dataToEdit.Hospitals[0] ? this.dataToEdit.Hospitals[0].id : null
      }
    }
  }
}
</script>

<style>

</style>