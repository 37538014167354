<template>
  <Modal :title="modalTitle" @close="$emit('close')" :loading="loading" @submit="addHospital">
    <form action="">
      <b-field grouped>
        <b-field label="Hospital Name *" expanded>
          <b-input placeholder="Hospital Name" expanded required v-model="formData.name"></b-input>
        </b-field>
        <b-field label="Hospital District *" expanded>
          <b-input placeholder="Hospital District" expanded required v-model="formData.district"></b-input>
        </b-field>
        <b-field label="Hospital Sector *" expanded>
          <b-input placeholder="Hospital Sector" expanded required v-model="formData.sector"></b-input>
        </b-field>
      </b-field>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { CREATE_HOSPITAL, UPDATE_HOSPITAL } from '@/store/actions/hospitals';

export default {
  name: 'AddUpdateData',
  components: {
    Modal
  }, 
  props: {
    dataToEdit: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modalTitle: 'Add Hospital',
      loading: false,
      formData: {
        name: '',
        district: '',
        sector: ''
      }
    }
  },
  methods: {
    addHospital() {
      let vm = this;
      vm.loading = true;

      if (this.formData.name == '' || this.formData.district == '' || this.formData.sector == '') {
        vm.loading = false;
        vm.$notify({
          title: 'Failed',
          text: 'Please fill all required fields',
          type: 'error'
        });
        return;
      }

      if(this.dataToEdit) {
        vm.$store.dispatch(UPDATE_HOSPITAL, this.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
            vm.$emit('close');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          vm.$notify({
            title: 'Error Warning',
            text: 'An error occurred. Please try again later.',
            type: 'error'
          });
          console.log(error);
        });
      } else {
        vm.$store.dispatch(CREATE_HOSPITAL, this.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
            vm.$emit('close');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          vm.$notify({
            title: 'Error Warning',
            text: 'An error occurred. Please try again later.',
            type: 'error'
          });
          console.log(error);
        });
      }
    }
  },
  mounted() {
    if(this.dataToEdit) {
      this.modalTitle = 'Update Hospital';
      this.formData = {
        name: this.dataToEdit.name,
        district: this.dataToEdit.district,
        sector: this.dataToEdit.sector,
        id: this.dataToEdit.id
      };
    }
  }
}
</script>

<style>

</style>