<template>
  <Modal
    :title="modalTitle"
    @close="$emit('close')"
    :loading="loading"
    @submit="addPatient"
  >
    <form action="">
      <div v-for="section in questionSections" :key="section.period">
        <h1 style="text-align: left; text-decoration: underline; margin-bottom: 10px; font-size: 1rem; text-transform: uppercase; font-weight: 600;">{{ section.period }}</h1>
        

        <b-field :label="`${question.id}. ${question.text}`" v-for="question in section.questions" :key="question.id">
          <b-select
            expanded
            placeholder="Choose Value"
            v-model="formData[`q${question.id}`]"
          >
            <option v-for="option in responseOptions" :value="option.value" :key="option.value">
              {{ option.text }}
            </option>
          </b-select>
        </b-field>
        <br>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { CREATE_PATIENT, CREATE_PATIENT_ASSESSMENT, UPDATE_PATIENT } from "@/store/actions/patients";
import { UPLOAD } from "@/store/actions/files";
import { VueEditor } from "vue2-editor";

export default {
  name: "AddUpdateAssessment",
  components: {
    Modal,
    VueEditor,
  },
  props: {
    dataToEdit: {
      type: Object,
      default: () => ({}),
    },
    patientId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        q31: "",
        q32: "",
        q33: "",
        q34: "",
        q35: "",
        q36: "",
        q37: "",
        q38: "",
        q39: "",
        q40: "",
        q41: "",
        q42: "",
        q43: "",
        q44: "",
        q45: "",
        q46: "",
        q47: "",
        q48: "",
        q49: "",
        q50: "",
        q51: "",
        q52: "",
        q53: "",
        q54: "",
      },
      modalTitle: "Add Patient Assessment",
      questionSections: [
        {
          period: "During the past week",
          questions: [
            { id: 31, text: "Have you had cramps in your abdomen?" },
            {
              id: 32,
              text: "Have you had difficulty in controlling your bowels?",
            },
            { id: 33, text: "Have you had blood in your stools (motions)?" },
            { id: 34, text: "Did you pass water/urine frequently?" },
            {
              id: 35,
              text: "Have you had pain or a burning feeling when passing water/urinating?",
            },
            { id: 36, text: "Have you had leaking of urine?" },
            { id: 37, text: "Have you had difficulty emptying your bladder?" },
            { id: 38, text: "Have you had swelling in one or both legs?" },
            { id: 39, text: "Have you had pain in your lower back?" },
            {
              id: 40,
              text: "Have you had tingling or numbness in your hands or feet?",
            },
            {
              id: 41,
              text: "Have you had irritation or soreness in your vagina or vulva?",
            },
            { id: 42, text: "Have you had discharge from your vagina?" },
            {
              id: 43,
              text: "Have you had abnormal bleeding from your vagina?",
            },
            { id: 44, text: "Have you had hot flushes and/or sweats?" },
            {
              id: 45,
              text: "Have you felt physically less attractive as a result of your disease or treatment?",
            },
            {
              id: 46,
              text: "Have you felt less feminine as a result of your disease or treatment?",
            },
            { id: 47, text: "Have you felt dissatisfied with your body?" },
          ],
        },
        {
          period: "During the past 4 weeks",
          questions: [
            { id: 48, text: "Have you worried that sex would be painful?" },
            { id: 49, text: "Have you been sexually active?" },
          ],
        },
        {
          period:
            "Answer these questions only if you have been sexually active during the past 4 weeks",
          questions: [
            {
              id: 50,
              text: "Has your vagina felt dry during sexual activity?",
            },
            { id: 51, text: "Has your vagina felt short?" },
            { id: 52, text: "Has your vagina felt tight?" },
            {
              id: 53,
              text: "Have you had pain during sexual intercourse or other sexual activity?",
            },
            { id: 54, text: "Was sexual activity enjoyable for you?" },
          ],
        },
      ],
      responseOptions: [
        { value: "NOT_AT_ALL", text: "Not at all" },
        { value: "A_LITTLE_BIT", text: "A little bit" },
        { value: "QUITE_A_BIT", text: "Quite a bit" },
        { value: "VERY_MUCH", text: "Very much" },
      ],
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    addPatient() {
      let vm = this;
      vm.loading = true;

      this.formData.patientId = this.patientId;
      if (this.dataToEdit) {
        vm.$store
          .dispatch(UPDATE_PATIENT, this.formData)
          .then((res) => {
            vm.loading = false;
            if (res.data.status == "ok") {
              vm.$notify({
                title: "Success",
                text: res.data.message,
                type: "success",
              });
              vm.$emit("completed");
              vm.$emit("close");
            } else {
              vm.$notify({
                title: "Failed",
                text: res.data.message,
                type: "error",
              });
            }
          })
          .catch((error) => {
            vm.loading = false;
            if (error.response && error.response.status === 400) {
              error.response.data.message.forEach((message) => {
                this.$notify({
                  title: "Error Warning",
                  text: message,
                  type: "error",
                });
              });
            } else {
              vm.$notify({
                title: "Error Warning",
                text: "An error occurred. Please try again later.",
                type: "error",
              });
              console.log(error);
            }
          });
      } else {
        vm.$store
          .dispatch(CREATE_PATIENT_ASSESSMENT, this.formData)
          .then((res) => {
            vm.loading = false;
            if (res.data.status == "ok") {
              vm.$notify({
                title: "Success",
                text: res.data.message,
                type: "success",
              });
              vm.$emit("completed");
              vm.$emit("close");
            } else {
              vm.$notify({
                title: "Failed",
                text: res.data.message,
                type: "error",
              });
            }
          })
          .catch((error) => {
            vm.loading = false;
            if (error.response && error.response.status === 400) {
              error.response.data.message.forEach((message) => {
                this.$notify({
                  title: "Error Warning",
                  text: message,
                  type: "error",
                });
              });
            } else {
              vm.$notify({
                title: "Error Warning",
                text: "An error occurred. Please try again later.",
                type: "error",
              });
              console.log(error);
            }
          });
      }
    },
  },
  mounted() {
    if (this.dataToEdit) {
      this.modalTitle = "Update Patient";
      this.formData = {
        firstName: this.dataToEdit.firstName,
        lastName: this.dataToEdit.lastName,
        phoneNumber: this.dataToEdit.phoneNumber,
        gender: this.dataToEdit.gender,
        dob: new Date(this.dataToEdit.dob),
        patientInfo: this.dataToEdit.patientInfo,
        doctorId: this.dataToEdit.patientDoctor.user.id,
        patientFile: this.dataToEdit.patientFile,
        id: this.dataToEdit.id,
        siteOfTumor: this.dataToEdit.siteOfTumor,
        province: this.dataToEdit.province,
        district: this.dataToEdit.district,
        stage: this.dataToEdit.stage,
        histologyType: this.dataToEdit.histologyType,
        otherHistologyType: this.dataToEdit.otherHistologyType,
        treatmentReceived: this.dataToEdit.treatmentReceived,
        otherTreatmentReceived: this.dataToEdit.otherTreatmentReceived,
      };
    }
  },
};
</script>

<style>
</style>