<template>
  <div class="dashboard_page">
    <div>
      <b-tabs :destroy-on-hide="false">
        <b-tab-item label="All Hospitals">
          <All @add="showModal = true" />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import All from "./pages/All";

export default {
  name: "Hospitals",
  data() {
    return {
      currentPage: "Active",
    };
  },
  components: {
    All,
  },
  methods: {
  },
};
</script>

<style>
</style>