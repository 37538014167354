export const GET_ALL_PATIENTS = "GET_ALL_PATIENTS";
export const GET_PATIENT = "GET_PATIENT";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const CREATE_PATIENT_RECORD = "CREATE_PATIENT_RECORD";
export const UPDATE_PATIENT_RECORD = "UPDATE_PATIENT_RECORD";
export const DELETE_PATIENT_RECORD = "DELETE_PATIENT_RECORD";
export const DOWNLOAD_PATIENT_RECORDS = "DOWNLOAD_PATIENT_RECORDS"; 

// PATIENT ASSESSMENTS
export const CREATE_PATIENT_ASSESSMENT = "CREATE_PATIENT_ASSESSMENT";
export const UPDATE_PATIENT_ASSESSMENT = "UPDATE_PATIENT_ASSESSMENT";
export const DELETE_PATIENT_ASSESSMENT = "DELETE_PATIENT_ASSESSMENT";
export const GET_PATIENT_ASSESSMENT = "GET_PATIENT_ASSESSMENT";