<script>
  export default {

    methods: {
      $parseJwt() {
        let cookieToken = this.$getCookie('access_token_cancer_treatment');
        let base64Url = cookieToken.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
      },
      $getCookie() {
        let name = 'access_token_cancer_treatment' + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        
        return "";

      },
      $setCookie(value) {
        var expires = "";
        var date = new Date();
        date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
        document.cookie = 'access_token_cancer_treatment' + "=" + (value || "") + expires + "; path=/";
      },
      $deleteCookie(name, path, domain) {
          if (getCookie(name)) {
            document.cookie = name + "=" +
              ((path) ? ";path=" + path : "") +
              ((domain) ? ";domain=" + domain : "") +
              ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
            return true;
          }
          return false;
      },
      $checkCookie() {
        let cookie = this.$getCookie('access_token_cancer_treatment');

        if (!cookie && this.$route.path !== '/') {
          this.$router.push('/');
        } else if (cookie && this.$route.path === '/') {
          this.$router.push('/dashboard');
        }
      },
      $logout() {
        this.$deleteCookie('access_token_cancer_treatment', '/');
        this.$router.push('/');
      }
    }
  }
</script>