<template>
  <div>
    <section v-if="!loading && data.length > 0">
      <b-button class="is-primary" @click="addOrUpdate = true">Add Patient</b-button>
      <br><br>
      <b-table :paginated="true" :per-page="10" pagination-size="is-small" :pagination-rounded="true" :striped="true" :hoverable="true" :data="data">
        <b-table-column field="id" label="ID" width="40" numeric>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ ++props.index }}
          </template>
        </b-table-column>
        <b-table-column field="fullNames" label="Names" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.fullNames }}
          </template>
        </b-table-column>
        <b-table-column field="gender" label="Gender" width="50" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.gender }}
          </template>
        </b-table-column>
        <b-table-column field="phoneNumber" label="Phone Number" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.phoneNumber }}
          </template>
        </b-table-column>
        
        <b-table-column field="dob" label="DOB" >
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.dob }}
          </template>
        </b-table-column>
        
        <b-table-column field="patientDoctorNames" label="Doctor" searchable >
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.patientDoctorNames }}
          </template>
        </b-table-column>

        <b-table-column field="" label="Next Appointment" >
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.records[0] ? dayjs(props.row.records[0].nextAppointment).format('MMM D, YYYY') : 'N/A' }}
          </template>
        </b-table-column>

        <b-table-column label="Action">
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            <b-button type="is-info" icon-left="account-alert" size="is-small" @click="patientToVisit = props.row.id; goToVisit = true">Details</b-button>
            &nbsp;
            <b-button type="is-success" icon-left="pencil" size="is-small" @click="dataToEdit = props.row; addOrUpdate = true">Edit</b-button>
            &nbsp;
            <b-button type="is-danger" icon-left="delete" size="is-small" @click="deletePatient(props.row)">Delete</b-button>
          </template>
        </b-table-column>
      </b-table>
    </section>
    <div v-else>
      <widgets v-if="loading"  />
      <Widgets type="noData" v-else :showButton="true" @add="addOrUpdate = true" />
    </div>
    <AddUpdateData @close="addOrUpdate = false; dataToEdit = null" v-if="addOrUpdate" :allDoctors="allDoctors" @completed="getPatients" :dataToEdit="dataToEdit" />
    <VisitPage v-if="goToVisit" @goback="goToVisit = false" :patientToVisit="patientToVisit" />
  </div>
</template>

<script>
import Widgets from '@/components/Widgets.vue';
import AddUpdateData from '../modals/AddUpdateData.vue';
import { DELETE_PATIENT, GET_ALL_PATIENTS } from '@/store/actions/patients';
import { GET_ALL_DOCTORS } from '@/store/actions/users';
import dayjs from 'dayjs';
import VisitPage from './VisitPage.vue';
export default {
  name: "Active",
  components: { Widgets, AddUpdateData, VisitPage },
  data() {
    return {
      loading: true,
      data: [],
      addOrUpdate: false,
      dataToEdit: null,
      allDoctors: [],
      dayjs: dayjs,
      goToVisit: false,
      patientToVisit: null
    };
  },
  methods: {
    getPatients() {
      let vm = this;
      vm.addOrUpdate = false;
      vm.loading = true;

      vm.$store.dispatch(GET_ALL_PATIENTS).then((res) => {
        vm.loading = false;
        if (res.data.status == 'ok') {
          res.data.data.forEach((patient) => {
            patient.fullNames = `${patient.firstName} ${patient.lastName}`;
            // patient.hospitalName = patient.Hospitals[0] ? patient.Hospitals[0].name : 'N/A';
            patient.patientDoctorNames = patient.patientDoctor ? `${patient.patientDoctor.user.firstName} ${patient.patientDoctor.user.lastName}` : 'N/A';
          })
          this.data = res.data.data;
          
        } else {
          this.$notify({
            title: 'Failed',
            text: res.data.message,
            type: 'error'
          });
        }
      }).catch((error) => {
        vm.loading = false;
        if (error.response && error.response.status === 401) {
          this.$notify({
            title: "Error",
            text: "You are not authorized to perform this action.",
            type: "error",
          });
          this.$router.push('/dashboard');
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred. Please try again later.",
            type: "error",
          });
          console.log(error);
        }
      });
    },
    getAllDoctors() {
      this.$store.dispatch(GET_ALL_DOCTORS).then((res) => {
        this.allDoctors = res.data.data.filter((user) => user.isActivated === true);
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          this.$notify({
            title: "Error",
            text: "You are not authorized to perform this action.",
            type: "error",
          });
          this.$router.push('/dashboard');
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred. Please try again later.",
            type: "error",
          });
          console.log(error);
        }
        
        console.log(error);
      });
    },
    deletePatient(data) {
      this.$buefy.dialog.confirm({
        title: 'Deleting patient',
        message: 'Are you sure you want to <b>delete</b> this patient? This action cannot be undone.',
        confirmText: 'Delete Patient',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(DELETE_PATIENT, data).then((res) => {
            if (res.data.status == 'ok') {
              this.$notify({
                title: 'Success',
                text: res.data.message,
                type: 'success'
              });
              this.getPatients();
            } else {
              this.$notify({
                title: 'Failed',
                text: res.data.message,
                type: 'error'
              });
            }
          }).catch((error) => {
            this.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error);
          });
        }
      })
      
    }
  },
  mounted() {
    this.getPatients();
    this.getAllDoctors();
  }
};
</script>

<style>
</style>