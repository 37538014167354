<template>
  <main class="dashboardLayout">
    <Sidebar />
    <div class="dashboardLayout_content">
      <Topnav :pageName="pageName" />
      <router-view />
    </div>
  </main>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Topnav from '@/components/Topnav.vue';
export default {
  data() {
    return {
      pageName: 'Dashboard'
    }
  },
  components: {
    Sidebar,
    Topnav
  },
  watch: {
    $route(to, from) {
      this.pageName = to.name;
    }
  }
}
</script>

<style>

</style>