<template>
  <div>
    <section v-if="!loading && data.length > 0">
      <b-button class="is-primary" @click="addOrUpdate = true">Add Doctor</b-button>
      <br><br>
      <b-table :paginated="true" :per-page="10" pagination-size="is-small" :pagination-rounded="true" :striped="true" :hoverable="true" :data="data">
        <b-table-column field="id" label="ID" width="40" numeric>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ ++props.index }}
          </template>
        </b-table-column>

        <b-table-column field="fullNames" label="Names" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.fullNames }}
          </template>
        </b-table-column>

        <b-table-column field="phoneNumber" label="Phone Number" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.phoneNumber }}
          </template>
        </b-table-column>

        <b-table-column field="email" label="Email" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            {{ props.row.email }}
          </template>
        </b-table-column>

        <b-table-column field="isActivated" label="Status">
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            <b-tag type="is-success" v-if="props.row.isActivated">Active</b-tag>
            <b-tag type="is-danger" v-else>Inactive</b-tag>
          </template>
        </b-table-column>

        <b-table-column field="hospitalName" label="Hospital" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            {{ props.row.hospitalName ? props.row.hospitalName : 'N/A' }}
          </template>
        </b-table-column>

        <b-table-column label="Action">
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            <b-button type="is-success" icon-left="pencil" size="is-small" @click="dataToEdit = props.row; addOrUpdate = true">Edit</b-button>
            &nbsp;
            <b-button type="is-danger" icon-left="delete" size="is-small" @click="deleteDoctor(props.row)">Delete</b-button>
          </template>
        </b-table-column>
      </b-table>
      
    </section>
    <div v-else>
      <widgets v-if="loading" />
      <Widgets type="noData" v-else :showButton="true" @add="addOrUpdate = true" />
    </div>
    <AddUpdateData @close="addOrUpdate = false; dataToEdit = null" :dataToEdit="dataToEdit"  v-if="addOrUpdate" :allHospitals="allHospitals" @completed="getAllDoctors" />
  </div>
</template>

<script>
import { DELETE_USER, GET_ALL_DOCTORS } from '@/store/actions/users';
import AddUpdateData from '../modals/AddUpdateData.vue';
import Widgets from '@/components/Widgets.vue';
import { GET_ALL_HOSPITALS } from '@/store/actions/hospitals';
export default {
  name: "Active",
  components: {
    AddUpdateData,
    Widgets
  },
  data() {
    return {
      loading: true,
      data: [],
      addOrUpdate: false,
      dataToEdit: null,
      allHospitals: []
    };
  },
  methods: {
    getAllDoctors() {
      this.loading = true;
      this.$store.dispatch(GET_ALL_DOCTORS).then((res) => {
        res.data.data.forEach((doctor) => {
          doctor.fullNames = `${doctor.firstName} ${doctor.lastName}`;
          doctor.hospitalName = doctor.Hospitals[0] ? doctor.Hospitals[0].name : 'N/A';
        })
        this.data = res.data.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;

        if (error.response && error.response.status === 401) {
          this.$notify({
            title: "Error",
            text: "You are not authorized to perform this action.",
            type: "error",
          });
          this.$router.push('/dashboard');
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred. Please try again later.",
            type: "error",
          });
          console.log(error);
        }
        
        console.log(error);
      });
    },
    getAllHospitals() {
      this.$store.dispatch(GET_ALL_HOSPITALS).then((res) => {
        this.allHospitals = res.data.data;
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          this.$notify({
            title: "Error",
            text: "You are not authorized to perform this action.",
            type: "error",
          });
          this.$router.push('/dashboard');
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred. Please try again later.",
            type: "error",
          });
          console.log(error);
        }
        
        console.log(error);
      });
    },
    deleteDoctor(data) {
      this.$buefy.dialog.confirm({
        title: 'Deleting doctor',
        message: 'Are you sure you want to <b>delete</b> this doctor? This action cannot be undone.',
        confirmText: 'Delete Doctor',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(DELETE_USER, data).then((res) => {
            if (res.data.status == 'ok') {
              this.$notify({
                title: 'Success',
                text: res.data.message,
                type: 'success'
              });
              this.getAllDoctors();
            } else {
              this.$notify({
                title: 'Failed',
                text: res.data.message,
                type: 'error'
              });
            }
          }).catch((error) => {
            this.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error);
          });
        }
      })
      
    }
  },
  mounted() {
    this.getAllDoctors();
    this.getAllHospitals();
  }
};
</script>

<style>
</style>