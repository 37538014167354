import { AUTH_LOGIN } from "../actions/auth";
import { API_BASE_URL, AUTH_LOGIN_URL } from "../variables"
import axios from 'axios'

const auth = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [AUTH_LOGIN]: ({ rootState, rootGetters }, payload) => {
            return axios({
                url: API_BASE_URL + AUTH_LOGIN_URL,
                method: "POST",
                data: payload
            })
        }
    }
}

export default auth;