import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './modules/auth'
import Users from './modules/users'
import Hospitals from './modules/hospitals'
import Patients from './modules/patients'
import upload from './modules/files'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    get_access_token() {
      let name = 'access_token_cancer_treatment' + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        
        return "";
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth,
    Users,
    Hospitals,
    Patients,
    upload
  }
})