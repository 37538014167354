<template>
  <Modal :title="modalTitle" @close="$emit('close')" @submit="addUser" :loading="loading">
    <form action="">
      <b-field grouped>
        <b-field label="First Name *" expanded>
          <b-input placeholder="First Name" v-model="formData.firstName" expanded required></b-input>
        </b-field>
        <b-field label="Last Name *" expanded>
          <b-input placeholder="Last Name" v-model="formData.lastName" expanded required></b-input>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Phone Number *" expanded>
          <b-input placeholder="Phone Number" v-model="formData.phoneNumber" expanded required></b-input>
        </b-field>
        <b-field label="Email *" expanded>
          <b-input placeholder="Email" expanded type="email" required v-model="formData.email"></b-input>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Password *" expanded>
          <b-input type="password" placeholder="Password" expanded :required="!updatingForm" v-model="formData.password"></b-input>
        </b-field>
        <b-field label="Role *" expanded>
          <b-select placeholder="Select Role" v-model="formData.role" expanded required>
            <option value="SUPER_ADMIN">SUPER ADMIN</option>
            <option value="DOCTOR">DOCTOR</option>
            <option value="ASSISTANT">ASSISTANT</option>
          </b-select>
        </b-field>
        <b-field label="Activate User ? *" expanded >
          <b-switch expanded v-model="formData.isActivated"></b-switch>
        </b-field>
      </b-field>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { CREATE_USER, UPDATE_USER } from '@/store/actions/users';

export default {
  name: 'AddUpdateData',
  components: {
    Modal
  }, 
  props: {
    dataToEdit: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modalTitle: 'Add User',
      loading: false,
      updatingForm: false,
      formData: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        role: '',
        isActivated: true
      }
    }
  },
  methods: {
    addUser() {
      let vm = this;
      vm.loading = true;

      if (this.dataToEdit) {
        if(this.formData.firstName == '' || this.formData.lastName == '' || this.formData.phoneNumber == '' || this.formData.email == ''  || this.formData.role == '') {
          vm.loading = false;
          vm.$notify({
            title: 'Failed',
            text: 'Please fill all required fields',
            type: 'error'
          });
          return;
        }

        if(this.formData.password == '') {
          delete this.formData.password;
        }

        this.$store.dispatch(UPDATE_USER, this.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          if(error.response && error.response.status === 400) {
            error.response.data.message.forEach((message) => {
              this.$notify({
                title: 'Error Warning',
                text: message,
                type: 'error'
              });
            });
          } else {
            this.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error);
          }
        });
      } else {
        if(this.formData.firstName == '' || this.formData.lastName == '' || this.formData.phoneNumber == '' || this.formData.email == '' || this.formData.password == '' || this.formData.role == '') {
          vm.loading = false;
          vm.$notify({
            title: 'Failed',
            text: 'Please fill all required fields',
            type: 'error'
          });
          return;
        }
        
        vm.$store.dispatch(CREATE_USER, vm.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          if(error.response && error.response.status === 400) {
            error.response.data.message.forEach((message) => {
              this.$notify({
                title: 'Error Warning',
                text: message,
                type: 'error'
              });
            });
          } else {
            this.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error);
          }
        });
      }

    }
  },
  mounted() {
    if(this.dataToEdit) {
      this.modalTitle = 'Update User';
      this.formData = {
        firstName: this.dataToEdit.firstName,
        lastName: this.dataToEdit.lastName,
        phoneNumber: this.dataToEdit.phoneNumber,
        email: this.dataToEdit.email,
        role: this.dataToEdit.role,
        isActivated: this.dataToEdit.isActivated,
        password: '',
        id: this.dataToEdit.id
      };
      this.updatingForm = true;
    }
  }
}
</script>

<style>

</style>