import { CREATE_USER, DELETE_USER, GET_ALL_DOCTORS, GET_ALL_MY_PATIENTS, GET_ALL_USERS, UPDATE_USER } from "../actions/users"
import { API_BASE_URL, CREATE_USER_URL, DELETE_USER_URL, GET_ALL_DOCTORS_URL, GET_ALL_MY_PATIENTS_URL, GET_ALL_USERS_URL, UPDATE_USER_URL } from "../variables"
import axios from 'axios'

const users = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [GET_ALL_USERS]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + GET_ALL_USERS_URL,
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          }
      })
    },
    [CREATE_USER]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + CREATE_USER_URL,
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          },
          data: payload
      })
    },
    [UPDATE_USER]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + UPDATE_USER_URL + payload.id,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          },
          data: payload
      })
    },
    [DELETE_USER]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + DELETE_USER_URL + payload.id,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          }
      })
    },
    [GET_ALL_DOCTORS]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + GET_ALL_DOCTORS_URL,
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          }
      })
    },
    [GET_ALL_MY_PATIENTS]: ({ rootState, rootGetters }, payload) => {
      return axios({
          url: API_BASE_URL + GET_ALL_MY_PATIENTS_URL,
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootGetters.get_access_token}`
          }
      })
    }

  }
}

export default users;