import axios from 'axios'
import { CREATE_PATIENT, CREATE_PATIENT_RECORD, DELETE_PATIENT, DOWNLOAD_PATIENT_RECORDS, GET_ALL_PATIENTS, GET_PATIENT, UPDATE_PATIENT, UPDATE_PATIENT_RECORD, CREATE_PATIENT_ASSESSMENT, UPDATE_PATIENT_ASSESSMENT, DELETE_PATIENT_ASSESSMENT, DELETE_PATIENT_RECORD } from "../actions/patients"
import { API_BASE_URL, CREATE_PATIENT_RECORD_URL, CREATE_PATIENT_URL, DELETE_PATIENT_URL, GET_ALL_PATIENTS_URL, GET_PATIENT_URL, UPDATE_PATIENT_RECORD_URL, UPDATE_PATIENT_URL, CREATE_PATIENT_ASSESSMENT_URL, DELETE_PATIENT_ASSESSMENT_URL, DELETE_PATIENT_RECORD_URL } from "../variables"

const Patients = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [GET_ALL_PATIENTS]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + GET_ALL_PATIENTS_URL + (payload && payload.startDate && payload.endDate ? `?startDate=${payload.startDate}&endDate=${payload.endDate}` : ""),
        method: "GET",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        }
      })
    },
    [DOWNLOAD_PATIENT_RECORDS]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + GET_ALL_PATIENTS_URL + (payload && payload.startDate && payload.endDate ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&download=${true}` : `?download=${true}`),
        method: "GET",
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        }
      })
    },
    [GET_PATIENT]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + GET_PATIENT_URL + payload.id,
        method: "GET",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        }
      })
    },
    [CREATE_PATIENT]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + CREATE_PATIENT_URL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        },
        data: payload
      })
    },
    [UPDATE_PATIENT]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + UPDATE_PATIENT_URL + payload.id,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        },
        data: payload
      })
    },
    [DELETE_PATIENT]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + DELETE_PATIENT_URL + payload.id,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        }
      })
    },
    [CREATE_PATIENT_RECORD]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + CREATE_PATIENT_RECORD_URL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        },
        data: payload
      })
    },
    [UPDATE_PATIENT_RECORD]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + UPDATE_PATIENT_RECORD_URL + payload.id,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        },
        data: payload
      })
    },
    [DELETE_PATIENT_RECORD]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + DELETE_PATIENT_RECORD_URL + payload.id,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        }
      })
    },
    // PATIENT ASSESSMENT
    [CREATE_PATIENT_ASSESSMENT]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + CREATE_PATIENT_ASSESSMENT_URL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        },
        data: payload
      })
    },
    [UPDATE_PATIENT_ASSESSMENT]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + UPDATE_PATIENT_ASSESSMENT_URL + payload.id,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        },
        data: payload
      })
    },
    [DELETE_PATIENT_ASSESSMENT]: ({ rootState, rootGetters }, payload) => {
      return axios({
        url: API_BASE_URL + DELETE_PATIENT_ASSESSMENT_URL + payload.id,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters.get_access_token}`
        }
      })
    },
  }
}

export default Patients;