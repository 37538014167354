<template>
  <div>
    <section v-if="!loading && data.length > 0">
      <b-button class="is-primary" @click="addOrUpdate = true">Add Hospital</b-button>
      <br><br>
      <b-table :paginated="true" :per-page="10" pagination-size="is-small" :pagination-rounded="true" :striped="true" :hoverable="true" :data="data">
        <b-table-column field="id" label="ID" width="40" numeric>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ ++props.index }}
          </template>
        </b-table-column>
        <b-table-column field="name" label="Name" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column field="district" label="District" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props">
            {{ props.row.district }}
          </template>
        </b-table-column>
        <b-table-column field="sector" label="Sector" searchable>
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            {{ props.row.sector }}
          </template>
        </b-table-column>
        <b-table-column field="" label="Doctors" >
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            {{ props.row.Users.length }}
          </template>
        </b-table-column>
        <b-table-column label="Action">
          <template v-slot:header="{ column }">
            <b-tooltip :label="column.label" append-to-body dashed>
              {{ column.label }}
            </b-tooltip>
          </template>
          <template v-slot="props" >
            <b-button type="is-success" icon-left="pencil" size="is-small" @click="dataToEdit = props.row; addOrUpdate = true">Edit</b-button>
            &nbsp;
            <b-button type="is-danger" icon-left="delete" size="is-small" @click="deleteHospital(props.row)">Delete</b-button>
          </template>
        </b-table-column>
      </b-table>
    </section>
    <div v-else>
      <widgets v-if="loading" />
      <Widgets type="noData" v-else :showButton="true" @add="addOrUpdate = true" />
    </div>
    <AddUpdateData v-if="addOrUpdate" @close="addOrUpdate = false; dataToEdit = null" @completed="getAllHospitals" :dataToEdit="dataToEdit" />
  </div>
</template>

<script>
import Widgets from '@/components/Widgets.vue';
import AddUpdateData from '../modals/AddUpdateData.vue';
import { DELETE_HOSPITAL } from '@/store/actions/hospitals';
export default {
  components: { Widgets, AddUpdateData },
  name: "Active",
  data() {
    return {
      loading: true,
      data: [],
      addOrUpdate: false,
      dataToEdit: null
    };
  },
  methods: {
    getAllHospitals() {
      this.loading = true;
      this.addOrUpdate = false;

      this.$store.dispatch("GET_ALL_HOSPITALS").then((res) => {
        this.loading = false;
        if (res.data.status == "ok") {
          this.data = res.data.data;
        } else {
          this.$notify({
            title: "Error",
            text: res.data.message,
            type: "error",
          });
        }
      }).catch((error) => {
        this.loading = false;

        if (error.response && error.response.status === 401) {
          this.$notify({
            title: "Error",
            text: "You are not authorized to perform this action.",
            type: "error",
          });
          this.$router.push('/dashboard');
        } else {
          this.$notify({
            title: "Error",
            text: "An error occurred. Please try again later.",
            type: "error",
          });
          console.log(error);
        }
        
        console.log(error);
      });
    },
    deleteHospital(data) {
      this.$buefy.dialog.confirm({
        title: 'Deleting hospital',
        message: 'Are you sure you want to <b>delete</b> this hospital? This action cannot be undone.',
        confirmText: 'Delete Hospital',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(DELETE_HOSPITAL, data).then((res) => {
            if (res.data.status == 'ok') {
              this.$notify({
                title: 'Success',
                text: res.data.message,
                type: 'success'
              });
              this.getAllHospitals();
            } else {
              this.$notify({
                title: 'Failed',
                text: res.data.message,
                type: 'error'
              });
            }
          }).catch((error) => {
            this.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error);
          });
        }
      })
      
    }
  },
  mounted() {
    this.getAllHospitals();
  }
};
</script>

<style>
</style>