<template>
  <Modal :title="modalTitle" @close="$emit('close')" :loading="loading" @submit="addRecords">
    <form action="">
      <b-field label="Patient Notes">
        <VueEditor :editorToolbar="customToolbar" v-model="formData.patientNotes" />
      </b-field>

      <b-field label="Patient Complaints">
        <b-checkbox v-model="formData.fatigue">Fatigue</b-checkbox>
        <b-checkbox v-model="formData.nausea">Nausea</b-checkbox>
        <b-checkbox v-model="formData.vomiting">Vomiting</b-checkbox>
        <b-checkbox v-model="formData.diarrhea">Diarrhea</b-checkbox>
        <b-checkbox v-model="formData.pain">Pain</b-checkbox>
        <b-checkbox v-model="formData.bleeding">Bleeding</b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="formData.weightLoss">Weight Loss</b-checkbox>
        <b-checkbox v-model="formData.vaginalDischarge">Vaginal Discharge</b-checkbox>
        <b-checkbox v-model="formData.vaginalBleeding">Vaginal Bleeding</b-checkbox>
        <b-checkbox v-model="formData.painDuringSex">Pain During Sex</b-checkbox>
      </b-field>
      <b-field>
        <b-input v-model="formData.otherComplaints" placeholder="Other Patient Complaints" />
      </b-field>

      <b-field label="Physical Exam Findings">
        <b-checkbox v-model="formData.normal">Normal</b-checkbox>
        <b-checkbox v-model="formData.vaginalDischargeExam">Vaginal Discharge</b-checkbox>
        <b-checkbox v-model="formData.massPalpable">Mass Palpable</b-checkbox>
        <b-checkbox v-model="formData.ulceration">Ulceration</b-checkbox>
        <b-checkbox v-model="formData.tenderness">Tenderness</b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="formData.vaginalStenosis">Vaginal Stenosis</b-checkbox>
        <b-checkbox v-model="formData.cervicalMass">Cervical Mass</b-checkbox>
      </b-field>
      <b-field>
        <b-input v-model="formData.othersPhysical" placeholder="Other Physical Exam Findings" />
      </b-field>

      <b-field label="Requested Test / Exams">
        <b-checkbox v-model="formData.cbc">CBC</b-checkbox>
        <b-checkbox v-model="formData.chemistryPanel">Chemistry Panel</b-checkbox>
        <b-checkbox v-model="formData.pelvicUltrasound">Pelvic Ultrasound</b-checkbox>
        <b-checkbox v-model="formData.ctScan">CT Scan</b-checkbox>
        <b-checkbox v-model="formData.mri">MRI</b-checkbox>
      </b-field>

      <b-field>
        <b-input v-model="formData.otherRequested" placeholder="Other Requested Test / Exams" />
      </b-field>

      <b-field>
        <b-input type="textarea" v-model="formData.comments" placeholder="Comment" />
      </b-field>

      <b-field label="Attachment" expanded>
        <b-upload v-model="dropFiles"
              multiple
              native
              drag-drop
              @input="fileUpload">
              <section class="section">
                  <div class="content has-text-centered">
                      <p>
                          <b-icon
                              icon="upload"
                              size="is-large">
                          </b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                  </div>
              </section>
          </b-upload>
      </b-field>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { UPLOAD } from '@/store/actions/files';
import { CREATE_PATIENT, CREATE_PATIENT_RECORD, UPDATE_PATIENT, UPDATE_PATIENT_RECORD } from '@/store/actions/patients';
import { VueEditor } from "vue2-editor";

export default {
  name: 'AddUpdateData',
  components: {
    Modal,
    VueEditor
  },
  props: {
    dataToEdit: {
      type: Object,
      default: () => ({})
    },
    patientId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        patientNotes: '',
        attachment: '',
        // Patient Complaints
        fatigue: false,
        nausea: false,
        vomiting: false,
        diarrhea: false,
        pain: false,
        bleeding: false,
        weightLoss: false,
        vaginalDischarge: false,
        vaginalBleeding: false,
        painDuringSex: false,
        otherComplaints: '',
        // Physical Exam Findings
        normal: false,
        vaginalDischargeExam: false,
        massPalpable: false,
        ulceration: false,
        tenderness: false,
        vaginalStenosis: false,
        cervicalMass: false,
        othersPhysical: '',
        // Requested Test / Exams
        cbc: true,
        chemistryPanel: true,
        pelvicUltrasound: true,
        ctScan: true,
        mri: true,
        otherRequested: '',
        comments: ''
      },
      modalTitle: 'Add Patient Record',
      dropFiles: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { list: 'check' }],
        [{ align: [] }],
        ["clean"]
      ]
    }
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    addRecords() {
      let vm = this;
      this.loading = true;

      if(this.formData.patientNotes === '' || this.formData.physicalExam === '') {
        this.$notify({
          title: 'Error',
          text: 'Please fill in all fields',
          type: 'error'
        });
        this.loading = false;
        return;
      }

      this.formData.patientId = this.patientId;

      if(this.dataToEdit) {
        vm.$store.dispatch(UPDATE_PATIENT_RECORD, this.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
            vm.$emit('close');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          if(error.response && error.response.status === 400) {
            error.response.data.message.forEach((message) => {
              this.$notify({
                title: 'Error Warning',
                text: message,
                type: 'error'
              });
            });
          } else {
            vm.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error)
          }
        });
      } else {
        vm.$store.dispatch(CREATE_PATIENT_RECORD, this.formData).then((res) => {
          vm.loading = false;
          if (res.data.status == 'ok') {
            vm.$notify({
              title: 'Success',
              text: res.data.message,
              type: 'success'
            });
            vm.$emit('completed');
            vm.$emit('close');
          } else {
            vm.$notify({
              title: 'Failed',
              text: res.data.message,
              type: 'error'
            });
          }
        }).catch((error) => {
          vm.loading = false;
          if(error.response && error.response.status === 400) {
            error.response.data.message.forEach((message) => {
              this.$notify({
                title: 'Error Warning',
                text: message,
                type: 'error'
              });
            });
          } else {
            vm.$notify({
              title: 'Error Warning',
              text: 'An error occurred. Please try again later.',
              type: 'error'
            });
            console.log(error)
          }
          console.log(error);
        });
      }
    },
    fileUpload(file) {
      const attachmentData = new FormData();
      attachmentData.append('file', file[0]);

      if(file) {
        this.modalTitle = 'Uploading...';
        this.loading = true;
        this.$store.dispatch(UPLOAD, attachmentData)
          .then((res) => {
            this.loading = false;
            this.modalTitle = 'Add Patient Record';
            if(res.data.status === 'success') {
              this.formData.attachment = res.data.file;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$notify({
              title: 'Error',
              text: 'An error occurred while uploading. Please try again later.',
              type: 'error'
            });
          });
      }
    }
  },
  mounted() {
    if(this.dataToEdit) {
      this.modalTitle = 'Update Patient Record';
      this.formData = {
        patientNotes: this.dataToEdit.patientNotes,
        id: this.dataToEdit.id,
        patientId: this.dataToEdit.patientId,
        attachment: this.dataToEdit.attachment,

        fatigue: this.dataToEdit.fatigue,
        nausea: this.dataToEdit.nausea,
        vomiting: this.dataToEdit.vomiting,
        diarrhea: this.dataToEdit.diarrhea,
        pain: this.dataToEdit.pain,
        bleeding: this.dataToEdit.bleeding,
        weightLoss: this.dataToEdit.weightLoss,
        vaginalDischarge: this.dataToEdit.vaginalDischarge,
        vaginalBleeding: this.dataToEdit.vaginalBleeding,
        painDuringSex: this.dataToEdit.painDuringSex,
        otherComplaints: this.dataToEdit.otherComplaints,

        normal: this.dataToEdit.normal,
        vaginalDischargeExam: this.dataToEdit.vaginalDischargeExam,
        massPalpable: this.dataToEdit.massPalpable,
        ulceration: this.dataToEdit.ulceration,
        tenderness: this.dataToEdit.tenderness,
        vaginalStenosis: this.dataToEdit.vaginalStenosis,
        cervicalMass: this.dataToEdit.cervicalMass,
        othersPhysical: this.dataToEdit.othersPhysical,

        cbc: this.dataToEdit.cbc,
        chemistryPanel: this.dataToEdit.chemistryPanel,
        pelvicUltrasound: this.dataToEdit.pelvicUltrasound,
        ctScan: this.dataToEdit.ctScan,
        mri: this.dataToEdit.mri,
        otherRequested: this.dataToEdit.otherRequested,
        comments: this.dataToEdit.comments,
      
      };
    }
  }
}
</script>

<style>

</style>